import React from 'react'

export default ({ highlightDetail }) => {
    return (
        <React.Fragment>
            <div>
                <div className="flex-container right">
                    <div className="bullet-point" />
                    <div className="p bullet">{highlightDetail}</div>
                </div>
                <div className="sm-spacing" />
            </div>
        </React.Fragment>
    );
}